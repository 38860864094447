// src/components/LoginPage.js
import React, { useState } from 'react';
import { Button, TextField, Container, Typography, Box, CircularProgress } from '@mui/material';
import styled from 'styled-components';
import logo from '../assets/logo.png';  // Import the logo image

const LoginContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

const LoginBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  margin-top: 25%;
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
`;

const Logo = styled.img`
  width: 156px;  // Adjust the width as needed
  margin-bottom: 0rem;  // Space between the logo and the title
`;

const LoginPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');

    const handleLogin = () => {
        let isValid = true;

        if (!email) {
            setEmailError(`L'adresse email est obligatoire`);
            isValid = false;
        } else {
            setEmailError('');
        }

        if (!password) {
            setPasswordError('Le mot de passe est obligatoire');
            isValid = false;
        } else {
            setPasswordError('');
        }

        if (!isValid) {
            return;
        }

        setLoading(true);
        // Simulate a login process with a timeout
        setTimeout(() => {
            setLoading(false);
            // Add your login logic here
        }, 200000);
    };

    return (
        <LoginContainer maxWidth="xs">
            <LoginBox>
                <Logo src={logo} alt="Sen Labo" />  {/* Add the logo here */}
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    error={!!emailError}
                    helperText={emailError}
                />
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Mot de passe"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    error={!!passwordError}
                    helperText={passwordError}
                />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    sx={{ mt: 3, mb: 2 }}
                    onClick={handleLogin}
                    disabled={loading}
                >
                    {loading ? <CircularProgress size={24} /> : 'Connexion'}
                </Button>
            </LoginBox>
        </LoginContainer>
    );
};

export default LoginPage;
