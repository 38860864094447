// src/App.js
import React from 'react';
import GlobalStyles from './styles/GlobalStyles';
import AppRoutes from './routes/Routes';

function App() {
  return (
      <>
        <GlobalStyles />
        <AppRoutes />
      </>
  );
}

export default App;
